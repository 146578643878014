import React, {useState, useEffect} from 'react';
// import FormInputField from '../../atoms/FormInputField/FormInputField';
import { bcApi } from '../../../helpers/bigcommerce';
import { isEmpty } from 'lodash';
import * as styles from './SelectCountry.module.css';

const SelectCountry = ({handleChange, value, error}) => {

    const [countries, setCountries] = useState([]);
    useEffect(() => {
        bcApi(`countries?limit=250`, 'GET', null, 2).then(response => {
            if (response.status === 200) {
                setCountries(response.response);
            }
        });
    }, [])

    return (
    <div className={`${styles.root} formField`}>
        <label className={styles.label} htmlFor="country">Country</label>
        {!isEmpty(countries) && (
            <div>
                <select
                    className={`${error !== undefined ? styles.error : ''}`}
                    id="country"
                    name="country"
                    onChange={e => {
                        let countryObject = false;
                        countries.map(country => {
                            if (country.country_iso2 === e.target.value) {
                                countryObject = country;
                            }
                            return true;
                        });
                        handleChange('country', countryObject);
                    }}
                    // onBlur={e => handleChange('country', e.target.value)}
                    value={value}
                >
                <option value={''}>Select a country</option>
                {countries.map((country, countryIndex) => {
                    return(
                        <option key={countryIndex} value={country.country_iso2}>
                        {country.country}
                        </option>
                    )
                })}
                </select>
            </div>
        )}
        {error && (
            <span className='error'>{error}</span>
        )}
    </div>
    );
};

export default SelectCountry;
