import React from 'react';
import { toast } from 'react-toastify';
import Icon from '../../components/atoms/Icon/Icon';

/**
 * Custom Toast Functions
 */
function success(message) {
    toast.success(message, {
        style: {
          marginTop: '80px'
        },
        icon:
          <div style={{width: '20px', height: '20px', color: 'var(--standard-white'}}>
            <Icon symbol={'check'}/>
          </div>
      });
}

function error(message) {
    toast.error(message);
}

export { success, error };