import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
// import { autoComplete } from '../../../helpers/here';
import Button from '../../atoms/Button/Button';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import SelectCountry from '../SelectCountry/SelectCountry';
import SelectState from '../SelectState/SelectState';
import Loader from '../../atoms/Loader/Loader';
// import { bcApi } from '../../../helpers/bigcommerce';
// import { useScript } from '../../../helpers/general'
import * as styles from './AddressForm.module.css';
import ContentfulContext from '../../../context/ContentfulProvider';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';

// require('dotenv').config();

const defaultAddress = {
  address1: '',
  address2: '',
  city: '',
  company: '',
  country_code: '',
  first_name: '',
  last_name: '',
  phone: '',
  postal_code: '',
  state_or_province: ''
};

const required = {
  first_name: 'First Name',
  city: 'City',
  country_code: 'Country',
  state_or_province: 'State/Province',
  last_name: 'Last Name',
  address1: 'Address 1',
  postal_code: 'Postcode/Zip'
};

const translateKeys = {
  'first_name': 'firstName',
  'last_name': 'lastName',
  'city': 'city',
  'country_code': 'countryCode',
  'state_or_province': 'stateOrProvince',
  'address1': 'address1',
  'postal_code': 'postalCode'
}

// const stateTranslations = {
//   'ACT': 'Australian Capital Territory',
//   'NSW': 'New South Wales',
//   'NT': 'Northern Territory',
//   'QLD': 'Queensland',
//   'SA': 'South Australia',
//   'TAS': 'Tasmania',
//   'VIC': 'Victoria',
//   'WA': 'Western Australia'
// };

// let autoComplete;

const AddressForm = ({ address, onCancel, onSubmit, visible, isSaving }) => {
  const contentful = useContext(ContentfulContext);
  const translations = get(contentful, 'translationData', {});

  const [addressData, setAddressData] = useState(defaultAddress);
  // const [countries, setCountries] = useState([]);
  // const [countryStates, setCountryStates] = useState([]);
  const [countryObject, setCountryObject] = useState();
  
  // const [isFetching, setIsFetching] = useState(false);
  const [formErr, setFormErr] = useState({});
  // const [query, setQuery] = useState('');
  // const [acInit, setAcInit] = useState(false);
  // const [autocompleteData, setAutocompleteData] = useState({});

  // const autoCompleteRef = useRef(null);

  // const fetchStates = countryIso => {
  //   // setIsFetching(true);
  //   let countryObject = false;
  //   countries.map(country => {
  //     if (country.country_iso2 === countryIso) {
  //       countryObject = country;
  //     }
  //     return true;
  //   });

  //   const endpoint = countryObject.states.resource.substring(1);
  //   bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(
  //     ({ response, status }) => {
  //       if (status === 200) {
  //         setCountryStates(response);
  //       } else {
  //         setCountryStates([]);
  //       }
  //       setIsFetching(false);
  //     }
  //   );
  // };

  // const handleUpdateValue = e => {
  //   const { value, name } = e.target;
  //   if (name === 'country_code') {
  //     setAddressData({ ...addressData, [name]: value, state_or_province: '' });
  //     // fetchStates(value);
  //   } else {
  //     setAddressData({ ...addressData, [name]: value });
  //   }
  //   window.addressData = addressData
  //   setFormErr({});
  // };

  const resetFieldErrorState = (idList) => {
    const newFormErr = {...formErr};
    for(let x = 0; x < idList.length; x++) {
      newFormErr[idList[x]] = undefined; 
    }
    setFormErr(newFormErr);
  }

  const handleChange = (id, fieldValue) => {
    const newField = {};
    if(id === 'country') {
      if(fieldValue === false) {
        newField['country_code'] = '';
        setCountryObject(undefined);
      } else {
        newField['country_code'] = fieldValue.country_iso2;
        setCountryObject(fieldValue);
        resetFieldErrorState(['country_code', 'state_or_province']);
      }
      newField['state_or_province'] = '';
    } else {
      newField[id] = fieldValue;
      if(fieldValue !== '') {
        resetFieldErrorState([id]);
      }
    }

    setAddressData({...addressData, ...newField});
  }

  const handleSubmit = () => {
    console.log('Handle submit', addressData);
    const errObj = {};
    let valid = true;
    Object.keys(required).forEach(key => {
      if (!addressData[key]) {
        valid = false;
        errObj[key] = `${get(translations, translateKeys[key] + '.title', required[key])} ${get(translations, 'accountPageEmptyFieldError.title', 'can not be blank')}.`;
      }
    });

    if (!valid) {
      setFormErr(errObj);
    } else {
       onSubmit(addressData);
    }
  };

  useEffect(() => {
    if (!isEmpty(address)) {
      setAddressData(address);
      // setQuery(address.address1)
      // if (address.country_code) {
      //   fetchStates(address.country_code);
      // }
    } 
    // else {
    //   setQuery('')
    // }
    // setAddressMessage('');
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    if (!visible) {
      setAddressData(defaultAddress);
      // setCountryStates([]);
      setFormErr({});
    }
  }, [visible]);

  // useEffect(() => {
  //   setAddressData(data => ({...data, ...autocompleteData}));
  // }, [autocompleteData]);

  // useEffect(() => {
  //   bcApi(`countries?limit=250`, 'GET', null, 2).then(response => {
  //     if (response.status === 200) {
  //       setCountries(response.response);
  //     }
  //   });
  // }, []);

  // useScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API}&libraries=places`, 'google', () => {
  // if (typeof document !== 'undefined') {
  //     const autocompleteField = document.getElementById('autocompleteField');
  //     if (autocompleteField && !acInit) {
  //       setAcInit(true);
  //       initialiseAutoComplete(setQuery, setAutocompleteData, autoCompleteRef);
  //     }
  //   }
  // });

  // const initialiseAutoComplete = (updateQuery, updateAutocompleteData, autoCompleteRef) => {
  //   autoComplete = new window.google.maps.places.Autocomplete(
  //     autoCompleteRef.current,
  //     { componentRestrictions: { country: "au" } }
  //   );
  //   autoComplete.setFields(["address_components", "formatted_address"]); // specify what properties we will get from API
  //   // add a listener to handle when the place is selected
  //   autoComplete.addListener("place_changed", () =>
  //     handlePlaceSelect(updateQuery, updateAutocompleteData)
  //   );
  // }

  // const handlePlaceSelect = async (updateQuery, updateAutocompleteData) => {
  //   const addressObject = autoComplete.getPlace();
  //   const query = addressObject.formatted_address;
  //   updateQuery(query);
  //   const addressComponents = {};
  //   addressObject.address_components.map(component => {
  //     const types = component.types.filter(type => type !== 'political');
  //     addressComponents[types[0]] = component;
  //     return true;
  //   });

  //   updateAutocompleteData({
  //     address1: `${addressComponents.street_number?.long_name || ''} ${addressComponents.route?.long_name} `,
  //     city: addressComponents.locality?.long_name,
  //     country_code: addressComponents.country?.short_name,
  //     postal_code: addressComponents.postal_code?.long_name,
  //     state_or_province: addressComponents.administrative_area_level_1?.long_name
  //   });
  // }

  // const [query, setQuery] = useState('');
  // const [addressResults, setAddressResults] = useState(false);
  // const [addressMessage, setAddressMessage] = useState(false);
  // const [autoTimeout, setAutoTimeout] = useState(null);

  // const triggerSearch = async (address) => {
  //   if (autoTimeout) {
  //       clearTimeout(autoTimeout);
  //   }
  //   setAutoTimeout(setTimeout(async () => {
  //       const suggestions = await autoComplete(address);
  //       console.log(suggestions);
  //       if (suggestions) {
  //         if (suggestions.type === 'results') {
  //             setAddressResults(suggestions.results);
  //             setAddressMessage('');
  //         } else {
  //             setAddressResults(false);
  //             setAddressMessage(suggestions[suggestions.type]);
  //         }
  //       }
  //   }, 500));
  // };

  // const searchAddress = (value) => {
  //   setQuery(value);
  //   triggerSearch(value);
  // }

//   const selectAddress = async (addressId) => {
//     const selectedAddress = addressResults.filter((address) => {
//         return address.locationId === addressId
//     });
//     if (selectedAddress.length > 0) {
//         const selectedAddressData = selectedAddress[0];
//         const field = {};
//         const addressProps = selectedAddressData.address;
//         // console.log(addressProps);
//         const addressField = `${(addressProps.unit && `${addressProps.unit}/`) || ''}${`${addressProps.houseNumber} ` || ''}${addressProps.street || ''}`;
//         // console.log("Full Address: ", addressField);
//         field.address1 = addressField;
//         field.city = addressProps.district !== undefined ? addressProps.district : addressProps.city;
//         field.state_or_province = addressProps.state !== undefined ? (stateTranslations[addressProps.state] ? stateTranslations[addressProps.state] : addressProps.state) : '';
//         field.country_code = selectedAddressData.countryCode.substr(0,2);
//         field.postal_code = addressProps.postalCode !== undefined ? addressProps.postalCode : '';

//         setQuery(addressField) // To save selected address in address 1 field
//         setAddressResults(false);
//         setAddressData({...addressData, ...field});
//     } else {
//         // TODO: Throw error
//     }
// }

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.formInputGrid}>
          <FormInputField 
            id='first_name' 
            labelName={get(translations, 'firstName.title', 'First Name')}
            value={addressData.first_name} 
            handleChange={handleChange} 
            error={formErr.first_name}/>
          {/* <label htmlFor="first_name">First Name</label>
          <input
            type="text"
            name="first_name"
            required
            onChange={handleUpdateValue}
            value={addressData.first_name}
          />
          {formErr.first_name && (
            <span className="error">{formErr.first_name}</span>
          )} */}
          <FormInputField id='last_name' 
            labelName={get(translations, 'lastName.title', 'Last Name')}
            value={addressData.last_name} 
            handleChange={handleChange} 
            error={formErr.last_name}
          />
      </div>
      {/* Commenting this out until auto complete field is resolved */}
      {/* {addressData.address1 === '' && (
        <>
          <div className="formField">
            <span className="label">Enter your address</span>
            <input type="text" autoComplete="new-password" id="autocompleteField" ref={autoCompleteRef} onChange={event => setQuery(event.target.value)} value={query} />
          </div>
          <div className="formField">
            <span className="label">Or enter below:</span>
          </div>
        </>
      )} */}
      {/* <div className="formField required">
        <label htmlFor="address1">Address 1</label>
        <input
          type="text"
          name="address1"
          required
          onChange={handleUpdateValue}
          value={addressData.address1}
        />
        {formErr.address1 && <span className="error">{formErr.address1}</span>}
      </div> */}

        <div className={styles.autoCompleteContainer}>
          <FormInputField id='address1' 
            labelName={get(translations, 'address1.title', 'Address 1')} 
            value={addressData.address1} 
            handleChange={handleChange} 
            error={formErr.address1}/>
            {/* {addressResults && addressResults.length > 0 && (
              <div className={styles.addressSearchResults}>
                  {addressResults.map((address, addressIndex) => {
                      return (
                          <div key={addressIndex} role="presentation" onClick={() => selectAddress(address.locationId)}>
                            {address.address ? (
                                address.address.label || (
                                    `${address.address.unit ? `${address.address.unit}/` : ''}${address.address.houseNumber ? `${address.address.houseNumber} ` : ''}
                                    ${address.address.street ? `${address.address.street}, ` : ''}
                                    ${address.address.district ? `${address.address.district}, ` : (address.address.city ? `${address.address.city}, ` : '')}
                                    ${address.address.state ? `${address.address.state}, ` : ''}
                                    ${address.address.country ? `${address.address.country} ` : ''}`
                                )
                            ) : (address.title || address.label)}
                          </div>
                      )
                  })}
              </div>
            )} */}
        </div>
        <FormInputField id='address2' 
          labelName={get(translations, 'address2.title', 'Address 2')}
          value={addressData.address2} 
          handleChange={handleChange} 
          error={formErr.address2}/>
      {/* <div className="formField">
        <label htmlFor="address2">Address 2</label>
        <input
          type="text"
          name="address2"
          onChange={handleUpdateValue}
          value={addressData.address2}
        />
      </div> */}
      <div className={styles.formInputGrid}>
      <SelectCountry 
          handleChange={handleChange}
          countryObject={countryObject}
          value={addressData.country_code} 
          error={formErr.country_code}
        />
        <SelectState 
          countryObject={countryObject}
          country={addressData.country_code}
          handleChange={handleChange} 
          value={addressData.state_or_province} 
          error={formErr.state_or_province} />

        {/* <div className="formField required">
          <label htmlFor="city">City</label>
          <input
            type="text"
            name="city"
            required
            onChange={handleUpdateValue}
            value={addressData.city}
          />
          {formErr.city && <span className="error">{formErr.city}</span>}
        </div> */}
        {/* <div className="formField required">
          <label htmlFor="postal_code">Postcode/Zip</label>
          <input
            type="text"
            name="postal_code"
            required
            onChange={handleUpdateValue}
            value={addressData.postal_code}
          />
          {formErr.postal_code && (
            <span className="error">{formErr.postal_code}</span>
          )}
        </div> */}
      </div>
      <div className={styles.formInputGrid}>
      <FormInputField id='city' 
            labelName={get(translations, 'city.title', 'City')}
            value={addressData.city} 
            handleChange={handleChange} 
            error={formErr.city}/>

          <FormInputField id='postal_code' 
            labelName={get(translations, 'postalCode.title', 'Postcode/Zip')} 
            value={addressData.postal_code} 
            handleChange={handleChange} 
            error={formErr.postal_code}/>
        {/* <div className="formField required">
          <label htmlFor="country_code">Country</label>
          {!isEmpty(countries) && (
            <select
              required
              name="country_code"
              onChange={handleUpdateValue}
              onBlur={handleUpdateValue}
              value={addressData.country_code}
            >
              <option>Select a country</option>
              {countries.map((country, countryIndex) => (
                <option key={countryIndex} value={country.country_iso2}>
                  {country.country}
                </option>
              ))}
            </select>
          )}
          {formErr.country_code && (
            <span className="error">{formErr.country_code}</span>
          )}
        </div> */}
        {/* <div className="formField required">
          <label htmlFor="state_or_province">State/Province</label>
          {!isEmpty(countryStates) && (
            <select
              name="state_or_province"
              required
              value={addressData.state_or_province}
              onChange={handleUpdateValue}
              onBlur={handleUpdateValue}
            >
              <option>Select a state</option>
              {countryStates.map((state, stateIndex) => (
                <option key={stateIndex} value={state.state}>
                  {state.state}
                </option>
              ))}
            </select>
          )}
          {isEmpty(countryStates) && (
            <input
              type="text"
              name="state_or_province"
              required
              value={addressData.state_or_province}
              onChange={handleUpdateValue}
            />
          )}
          {isFetching && (
            <div className={styles.spinnerWrapper}>
              <Loader size={'small'} />
            </div>
          )}
          {formErr.state_or_province && (
            <span className="error">{formErr.state_or_province}</span>
          )}
        </div> */}
      </div>
      <div className={styles.formInputGrid}>
          <FormInputField id='company' 
            labelName={get(translations, 'companyName.title', 'Company Name')}
            value={addressData.company} 
            handleChange={handleChange} 
            error={formErr.company}/>
          <FormInputField id='phone' 
            labelName={get(translations, 'phoneNumber.title', 'Phone Number')}
            value={addressData.phone} 
            handleChange={handleChange} 
            error={formErr.phone}/>
        {/* <div className="formField">
          <label htmlFor="company">Company</label>
          <input
            type="text"
            name="company"
            onChange={handleUpdateValue}
            value={addressData.company}
          />
        </div> */}
        {/* <div className="formField">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            name="phone"
            onChange={handleUpdateValue}
            value={addressData.phone}
          />
        </div> */}
      </div>
      <div className={`row ${styles.addressFormButtons}`}>
        <Button
          type="span"
          level="primary"
          className={isSaving ? 'disabled' : ''}
          onClick={handleSubmit}
        >
          {!isSaving ? get(translations, 'saveAddress.title', 'Save address') : get(translations, 'saving.title', 'Saving') + '...'}
        </Button>
        <Button
          type="span"
          level="secondary"
          onClick={() => {
            // setAcInit(false); 
            // setQuery(''); 
            onCancel();
          }}
          className="ml-4"
        >
          <ContentfulTranslationText keyName="cancel">Cancel</ContentfulTranslationText>
        </Button>
      </div>
      {isSaving && (
        <div className={styles.rootSpinner}>
          <Loader />
        </div>
      )}
    </div>
  );
};

AddressForm.propTypes = {
  address: PropTypes.shape({}),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAddAddress: PropTypes.func
};

AddressForm.defaultProps = {
  address: {},
  onEdit: () => null,
  onAddAddress: () => null,
  onDelete: () => null
};

export default AddressForm;
