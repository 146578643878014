import React, {useState, useEffect} from 'react';
import Loader from '../../atoms/Loader/Loader';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import { bcApi } from '../../../helpers/bigcommerce';
import { isEmpty } from 'lodash';
import * as styles from './SelectState.module.css';

const SelectState = ({countryObject, handleChange, value, error}) => {

    const [countryStates, setCountryStates] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if(countryObject !== false && countryObject !== undefined) {
            const endpoint = countryObject.states.resource.substring(1);
            setIsFetching(true);
            bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(
              ({ response, status }) => {
                if (status === 200) {
                  setCountryStates(response);
                } else {
                  setCountryStates([]);
                }
                setIsFetching(false);
              }
            );
        }
    }, [countryObject])

    return (
        <div className={`${styles.root}`}>
        {!isEmpty(countryStates) && (
        <div className={`formField`}>
            <label className={styles.label} htmlFor="state_or_province">State/Province</label>
            <select
                className={`${error !== undefined ? styles.error : ''}`}
                id="state_or_province"
                name="state_or_province"
                value={value}
                onChange={e => handleChange('state_or_province', e.target.value)}
                onBlur={() => {return true}}
            >
                <option value={''}>Select a state</option>
                {countryStates.map((state, stateIndex) => (
                    <option key={stateIndex} value={state.state}>
                    {state.state}
                    </option>
                ))}
            </select>
            {error && (
                <span className='error'>{error}</span>
            )}
        </div>
        )}
        {isEmpty(countryStates) && (
            <FormInputField id='state_or_province' 
            labelName='State/Province' 
            value={value} 
            handleChange={handleChange} 
            error={error}/>
        )}
        {isFetching && (
            <div className={styles.spinnerWrapper}>
                <Loader size={'small'} />
            </div>
        )}
    </div>
    );
};

export default SelectState;
