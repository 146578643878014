import React, { useContext, useEffect, useState } from "react"
import { isEmpty, isUndefined, head, get } from 'lodash';
import { bcApi } from '../../helpers/bigcommerce'
import { success } from '../../helpers/toast';

import AddressCard from '../../components/molecules/AddressCard/AddressCard';
import AddressForm from '../../components/molecules/AddressForm/AddressForm';
import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"

import * as styles from './addresses.module.css';
import ContentfulContext from "../../context/ContentfulProvider";
import ContentfulTranslationText from "../../components/atoms/ContentfulTranslationText/ContentfulTranslationText";

const Addresses = ({ customerId, transObj }) => {
    const translations = transObj;
    
    const [addresses, setAddresses] = useState([])
    const [active, setActive] = useState(null);
    const [fetched, toggleFetched] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState(false);

    const addAddress = () => setActive({});

    const editAddress = address => setActive(address);

    const cancelUpdate = () => {
      setActive(null);
      setErrors(false)
    };

    const handleResponse = (response, isNew) => {
        const address = head(response.data);
        if (address) {
            let updated = isNew
                ? [...addresses, address]
                : addresses.map(a => (a.id === address.id ? address : a));
            updated.filter(x => x);
            setAddresses(updated);
        }
    };

    const saveAddress = address => {
        if (isEmpty(address)) {
            return;
        }
        setIsSaving(true);
        setErrors(false);
        if (isUndefined(address.id)) {
            bcApi(`customers/addresses`, 'POST', [
                { ...address, customer_id: customerId }
            ]).then(({ response, status }) => {
                if (status === 200 && !isEmpty(response.data)) {
                  handleResponse(response, true);
                  success(get(translations, 'addressAdded.title', 'Address added'))
                  setActive(null);
                } else {
                  setErrors(response ? response.title : 'Creation failed! please check your details and try again.')
                }
                setIsSaving(false);
            });
        } else {
          // update address
          bcApi(`customers/addresses`, 'PUT', [address]).then(
              ({ response, status }) => {
                if (status === 200) {
                  setActive(null);
                  success(get(translations, 'addressUpdated.title', 'Address updated'))
                  if (!isEmpty(response.data)) {
                    handleResponse(response);
                  }
                }
                setIsSaving(false);
              }
          );
        }
    };

    const removeAddress = addressId => {
        // delete address
        const endpoint = `customers/addresses?id:in=${addressId}`;
        bcApi(endpoint, 'DELETE').then(response => {
            if (response.status === 204) {
                setAddresses(addresses.filter(x => x.id !== addressId));
            }
        });
    };

    useEffect(() => {
        if (!fetched && customerId > 0) {
            bcApi(`customers/addresses?customer_id:in=${customerId}`).then(
                ({ response, status }) => {
                if (typeof response === 'object' && status === 200) {
                    setAddresses(response.data);
                }
                toggleFetched(true);
                }
            );
        }
    }, [fetched, customerId]);

    return (
        <>
          {fetched && addresses && addresses.length === 0 && (
            <span className={styles.description}><ContentfulTranslationText keyName="noSavedAddress">You have no saved addresses</ContentfulTranslationText></span>
          )}
    
          {fetched && !active && (
            <div className={styles.container}>
              {addresses?.map((address, addressIndex) => (
                <AddressCard
                  address={address}
                  key={addressIndex}
                  onEdit={() => editAddress(address)}
                  onDelete={() => removeAddress(address.id)}
                />
              ))}
              <AddressCard onAddAddress={addAddress} />
            </div>
          )}
    
          <AddressForm
            address={active}
            visible={!!active}
            isSaving={isSaving}
            onSubmit={saveAddress}
            onCancel={cancelUpdate}
          />
          {active && errors && <span className='genericError'>{errors}</span>}
    
          {!fetched && <span><ContentfulTranslationText keyName="fetchingAddress">Fetching your stored addresses</ContentfulTranslationText>...</span>}
        </>
      );
    };
    
    const AddressesOutput = () => {
      const contentful = useContext(ContentfulContext);
      const translations = get(contentful, 'translationData', {});

      return (
        <AccountPageWrapper metaTitle="Account - Addresses" title={get(translations, 'addresses.title', 'Addresses')}>
          <Addresses transObj={translations} />
        </AccountPageWrapper>
      )
    }
    
    export default AddressesOutput;